import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, ButtonToolbar, FormControl, Modal } from "react-bootstrap";

import glpk from "glpk.js";
import DataGridXL from "./datagridxl";

type Data = {
  value: string | null;
  use: string | null;
};

const solver = async (desiredValue: number, data: Data[]) => {
  let fn = glpk as any;

  let pk: glpk.GLPK = await fn();


  let nonNulls = data
    .slice(
      0,
      data.findIndex((d) => d.value == null)
    )
    .map((v) => Number(v.value));


  let problem = {
    name: "sum",
    objective: {
      direction: pk.GLP_MAX,
      name: "total",
      vars: nonNulls.map((d, ix) => ({ name: ix.toString(), coef: d })),
    },
    subjectTo: [
      {
        name: "maxval",
        vars: nonNulls.map((d, ix) => ({ name: ix.toString(), coef: d })),
        bnds: {
          type: pk.GLP_UP,
          ub: desiredValue + 0.001,
          lb: desiredValue - 0.001,
        },
      },
    ],
    binaries: nonNulls.map((_, ix) => ix.toString()),
  };

  let res = await pk.solve(problem, {
    msglev: pk.GLP_MSG_ALL,
  });

  console.log(res);
  let vars = res.result.vars;

  let newData = data.map((d, ix) => ({ ...d, use: vars[ix] != null ? String(vars[ix]) : null }));

  return newData;
};

type GridProps<T> = {
  data: T[];
  onChange: (grid: any) => void;
};

function Grid<T>(props: GridProps<T>) {
  let elementRef = useRef(null);

  let gridRef = useRef(null as any);

  useEffect(() => {
    gridRef.current?.setData(props.data);
  }, props.data);

  useEffect(() => {
    let grid = new DataGridXL("internal-id", { data: props.data });
    gridRef.current = grid;

    grid.events.on("cellvaluechange", (e: unknown) => {
      console.log(e);
      props.onChange(grid);
    });
  }, []);

  return <div id="internal-id" style={{ height: "100%" }} ref={elementRef}></div>;
}

const useDialog = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let Component = useMemo(
    () => (props: { title: string; children: React.ReactFragment }) =>
      (
        <Modal show={show} size="lg" onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{props.children}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      ),
    [show]
  );

  return [Component, handleShow] as const;
};

const App = () => {
  const [rowData, setData] = useState<Data[]>(
    new Array(3000).fill(null).map((_) => ({ value: null, use: null }))
  );
  const [desiredVal, seDesiredVal] = useState(0);

  let solve = async () => {
    let d = await solver(desiredVal, rowData);
    setData(d);
    let text = d.map((v) => v.use).join("\n");
    setSolution(text);

    var type = "text/plain";
    var blob = new Blob([text], { type });
    try {
      var data = [new ClipboardItem({ [type]: Promise.resolve(blob) })];
      await navigator.clipboard.write(data);
    } catch (e) {}
  };

  const [Help, showHelp] = useDialog();

  const [solution, setSolution] = useState("");

  return (
    <>
      <ButtonToolbar style={{ height: "3.5rem" }} className="p-2">
        <FormControl
          className="me-2"
          style={{maxWidth: '10rem', display: 'inline-block'}}
          type="text"
          placeholder="Desired sum"
          onChange={(e) => seDesiredVal(Number(e.target.value))}
        />
        <Button className="me-2" onClick={solve}>Solve</Button>
        <Button className="me-2" onClick={showHelp}>Help</Button>
      </ButtonToolbar>
      <div
        style={{
          minHeight: "10rem",
          height: "calc(100vh - 3.5rem)",
          width: "100vw",
        }}
      >
        <Grid data={rowData} onChange={(grid) => setData(grid.getData())} />
      </div>
      <Help title="About this solver">
        <p>This solver will find a subset of values that sum up a desired sum. The current limit is 3000 items. If its unable to find the exact sum, it will return the maximum possible number below the desired sum</p>

        <h5 style={{paddingTop:"1rem"}}>How to use it?</h5>
        <p>Paste your numbers into the values column, enter your desired sum at the top and click on the <b>Solve</b> button. At the end, the <b>use</b> column will contain either <b>1</b> if the value for that row was used to get the sum, or <b>0</b> if the value was not used</p>

        <h5 style={{paddingTop:"1rem"}}>Does this application send my data over the internet?</h5>
        <p>No, the application does not send any of your data to any server. It will load and run entirely in your browser, on your own computer.</p>
      </Help>
    </>
  );
};

export default App;
